import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { graphql } from 'gatsby'

import { Layout } from '../components/Layout'
import { Container } from '../components/Container'
import { SEO } from '../components/SEO'
import { RichText, RichTextContainer } from '../components/RichText'

const ArticleWrapper = styled.article`
  ${tw`py-8`}
`

const ArticleHeader = styled.header`

`

const ArticleTitle = styled.h1`
  ${tw`text-5xl`}
  ${tw`font-semibold`}
`

const ArticleContainer = styled(Container)`
  @media (min-width: 640px) {
    ${tw`grid`}
    ${tw`gap-16`}
    grid-template-columns: minmax(0, 1fr) 300px;
  }
`

const ArticleContent = styled.div`
  ${RichTextContainer} {
    p {
      margin-right: 16%;
    }
  }
`

const ArticleSidebar = styled.aside`
`

const Article = ({
  data
}) => {
  const {
    author,
    content,
    title,
    excerpt
  } = data.article

  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
      />
      <ArticleWrapper>
        <ArticleHeader>
          <Container>
            <ArticleTitle>{title}</ArticleTitle>
            {author.name}
          </Container>
        </ArticleHeader>
        <ArticleContainer>
          <ArticleContent>
            <RichText
              content={content}
            />
          </ArticleContent>
          <ArticleSidebar>
            <></>
          </ArticleSidebar>
        </ArticleContainer>
      </ArticleWrapper>
    </Layout>
  )
}

export default Article

export const articleQuery = graphql`
  query ArticleBySlug($slug: String!) {
    article: strapiArticle(slug: { eq: $slug }) {
      title
      excerpt
      content
      author {
        name
      }
    }
  }
`